body {
    #background-color: #90645A;
    background-color: #ffdae9;
    align-content: center;
}

.Homepage {
    padding-top: 20px;
    padding-bottom: 80px;
    text-align: center;
//background-color: #fffbf6;
//box-shadow: #90645A 5px 20px 400px;
    font-family: "Comfortaa", serif;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-height: 70vh;
}

h4 {
    margin: 0 0 30px 0;color: black;
    text-shadow: 2px 2px 2px #ffc6f5;

}

.WelcomeInfo, .AboutMe {
    font-size: 16px;
    color: black;
    text-shadow: 2px 2px 2px #ffc6f5;
    padding-top: 0px;
    padding-bottom: 30px;
    line-height: 1.6;
}

.InputArea {
    padding-bottom: 10px;
}

.EmployeeQuery {
    display: inline-flex;
}

.QueryElement {
    padding-left: 20px;
    padding-right: 20px;
}

.CustomDepartmentQuery {
    display: block;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.react-json-view {
    display: inline-flex;
    padding-left: 5%;
    padding-right: 5%;

    border-radius: 30px;
    flex-direction: column;
    overflow: hidden;
    min-height: 50vh;
    max-height: 50vh;
    min-width: 40%;
    max-width: 40%;
}

.pretty-json-container {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 22px;
    text-align: left;
    display: inline-block;
    overflow-y: auto;
}

input, select {
    font-family: "Comfortaa", serif;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 15px;
    outline: none;
    transition: border-color 0.3s ease;
}

select {
    padding: 10px;
    background-color: white;
}

/* Basic button styles */
input[type=submit] {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px 0 10px 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    background-color: #ff29a8;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover effect */
input[type=submit]:hover {
    background-color: #a90264;
}

/* Focus effect */
input[type=submit]:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(166, 0, 38, 0.7);
}

/* Disabled state */
input[type=submit]:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Basic text input styles */
input[type=text], input[type=number]{
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.SalaryRange {
    margin-left: -40px;
    margin-right: -40px;
    width: 100%;
    display: inline-flex;
}

.SalaryItem {
    display: flex;
}

input[type=number] {
    /*width: 40%;*/
    display: flex;
    flex: 1;
}

/* Placeholder text color */
input[type=number], input[type=text] {
    color: #4f4f4f;
}